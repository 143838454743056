.canvas-sandbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px auto;

  .canvas-container {
    border: 1px solid #c00;
    margin-right: 20px;
  }

  .canvas-controls > div {
    margin-bottom: 10px;
  }

  button {
    background: rgb(108, 127, 255);
    color: #fff;
    padding: 0 10px;
    height: 32px;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;

    &:active {
      background: rgba(70, 102, 255, 1);
    }
  }
}